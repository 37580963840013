<template>
	<div class="md:text-center lg:text-left">
		<section class="relative w-full h-full text-black lg:py-4 cta-section">
			<AngledBackground
				class="absolute bottom-0 left-0 right-0 text-white"
			/>
			<div class="relative flex flex-wrap max-w-6xl px-3 py-4 mx-auto">
				<div class="pb-3 lg:w-1/2 xl:pr-5">
					<div class="pt-4 pb-6">
						<h1 class="pb-4 text-4xl font-bold leading-relaxed">
							Business Solutions for {{ pluralName }}.
						</h1>
						<p class="text-xl leading-relaxed break-normal">
							Are you a
							<span class="lowercase">
								{{ businessName }}
							</span>
							looking for more eyes on your business & {{ businessAudience }} through your doors? We’ve got you covered with a handful of solutions specifically designed to help take your business to the next level.
						</p>
					</div>
					<a
						href="#partners"
						class="px-3 py-2 pt-2 mr-3 text-sm font-semibold text-white uppercase bg-green-500 rounded shadow-sm"
					>
						LIST YOUR BUSINESS
					</a>
				</div>
				<div class="w-full lg:w-1/2">
					<img
						src="/img/tour/tour-head.png"
						alt="Tour Head"
					>
				</div>
			</div>
		</section>

		<section class="flex flex-wrap max-w-6xl px-3 mx-auto">
			<div class="flex flex-wrap items-center w-full py-4">
				<div class="w-full lg:w-1/2">
					<img
						src="/img/tour/t-feat-d-search.png"
						alt="Tour Search"
						class="relative w-full max-w-sm -left-12"
					>
				</div>
				<div class="w-full lg:w-1/2">
					<h2 class="pb-2 text-3xl font-bold">
						Digital Advertising
					</h2>
					<p class="text-xl leading-relaxed break-normal">
						Our website receives over 85% organic SEO-related traffic, which means we inherently attract highly qualified {{ audienceName }} with an even higher purchase intent. Whether you choose to simply claim your listing or opt for additional paid opportunities for enhanced visibility, we’ll work closely with your team to help you make the best marketing decisions for your business.
					</p>
				</div>
			</div>

			<div class="flex flex-wrap items-center py-4 my-4">
				<div class="w-full lg:w-1/2">
					<h2 class="pb-2 text-3xl font-bold">
						Listings
					</h2>
					<p class="text-xl leading-relaxed break-normal">
						We’re more than just a listing site. Our comprehensive directories provide a legal advertising platform for cannabis {{ listingType }} to reach qualified customers in a highly regulated industry. Claim your business profile page today, then optimize your listing and leverage other functionality to push more traffic and sales through your doors.
					</p>
				</div>
				<div class="w-full lg:w-1/2">
					<img
						src="/img/tour/t-feat-d-listing.png"
						alt="Tour Listing"
						class="w-full max-w-sm"
					>
				</div>
			</div>

			<div
				v-if="showProductSections"
				class="flex flex-wrap items-center w-full py-4 my-4"
			>
				<div class="w-full lg:w-1/2">
					<img
						src="/img/tour/t-feat-d-orders.png"
						alt="Tour Orders"
					>
				</div>
				<div class="w-full lg:w-1/2">
					<h2 class="pb-2 text-3xl font-bold">
						Online Pre-Ordering
					</h2>
					<p class="text-xl leading-relaxed break-normal">
						Want more sales? Our seamless online ordering functionality can be added to any business profile page, ensuring visitors can find and search thousands of products to buy for pickup or delivery (if applicable). We facilitate the pre-order, taking out most of the leg work so your team can simply worry about fulfillment & great customer service.
					</p>
				</div>
			</div>

			<div
				v-if="showProductSections"
				class="flex flex-wrap items-center py-4 my-4"
			>
				<div class="w-full lg:w-1/2">
					<h2 class="pb-2 text-3xl font-bold">
						Menus &amp; Products
					</h2>
					<p class="text-xl leading-relaxed break-normal">
						In today’s digital age and rise in online ordering, consumers expect to see real-time menus with available products to purchase. With complimentary POS integrations with almost every platform (alongside automatic syncs), let us help your business rise to the occasion to capture sales from ready-to-buy customers.
					</p>
				</div>
				<div class="w-full lg:w-1/2">
					<img
						src="/img/tour/t-feat-d-1.png"
						alt="Tour Items"
					>
				</div>
			</div>

			<div class="flex flex-wrap items-center w-full py-4 my-4">
				<div class="w-full lg:w-1/2">
					<img
						src="/img/tour/t-feat-d-deals.png"
						alt="Tour Deals"
					>
				</div>
				<div class="w-full lg:w-1/2">
					<h2 class="pb-2 text-3xl font-bold">
						Deals & Promotions
					</h2>
					<p class="text-xl leading-relaxed break-normal">
						Everyone likes to save money, especially our visitors and your future customers. Encourage more sales with engaging deals that get people excited and down the purchase funnel, faster. Opt to boost a deal for even greater visibility and potential sales.
					</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script async>
import AngledBackground from '@/assets/backgrounds/AngledBackground.vue'

export default {
	components: {
		AngledBackground
	},
	props: {
		businessType: {
			type: String,
			required: true
		}
	},
	computed: {
		businessName() {
			switch (this.businessType) {
				case 'doctor':
					return 'Marijuana Doctor'
				case 'delivery':
					return 'Delivery Service'
				case 'smoke-shop':
					return 'Smoke Shop'
				case 'dispensary':
					return 'Dispensary'
				default:
					return 'Business'
			}
		},
		pluralName() {
			switch (this.businessType) {
				case 'dispensary':
					return 'Dispensaries'
				default:
					return this.businessName + 's'
			}
		},
		businessAudience() {
			switch (this.businessType) {
				case 'doctor':
					return 'patients'
				case 'smoke-shop':
					return 'customers'
				case 'dispensary':
					return 'sales'
				case 'delivery':
					return 'sales'
				default:
					return 'users'
			}
		},
		audienceName() {
			switch (this.businessType) {
				case 'doctor':
					return 'patients'
				case 'smoke-shop':
					return 'patients'
				default:
					return 'users'
			}
		},
		showProductSections() {
			switch (this.businessType) {
				case 'delivery':
					return true
				case 'dispensary':
					return true
				default:
					return false
			}
		},
		listingType() {
			switch (this.businessType) {
				case 'doctor':
					return 'doctors'
				case 'smoke-shop':
					return 'doctors'
				default:
					return 'businesses'
			}
		}
	}
}
</script>

<style scoped>
	.text-ghost-white {
		color: #f8f9fb;
	}
	.cta-section {
		min-height: 500px;
		background-color: #f8f9fb;
	}
</style>
